import { createCacheKey } from "../utils/createCacheKey.mjs";
export function CachedGetter(timeInSeconds = Infinity) {
    return function (target, propName, descriptor) {
        if (descriptor.get) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const origFn = descriptor.get;
            descriptor.get = function () {
                const cacheKey = createCacheKey(propName, []);
                const cachedValue = this.getFromCache(cacheKey);
                if (cachedValue) {
                    return cachedValue;
                }
                const result = origFn.call(this);
                this.setCache(cacheKey, result, timeInSeconds);
                return result;
            };
        }
        return descriptor;
    };
}
